import React, { useState } from 'react';
// import logo from '../images/logo.png';
// import carewindow from '../images/carewindow.png';
import { Link } from 'react-router-dom'
// import axios from 'axios';
import '../css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
//toast
import toast, { Toaster } from 'react-hot-toast';
import carewindow_blue from '../images/carewindow_blue.png'

import '../style.css';

import '../css/responsive.css';
import '../css/perfect-scrollbar.css';
import '../css/custom.css';
import '../css/color_2.css';
import '../css/bootstrap-select.css';
import { generateRandomString } from './header';

function Send_Email() {
  const [email, setEmail] = useState('')
  const navigate =useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault() // Prevent default form submission

    if (!email) {
      alert("Please enter an email address.")
      return
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        {
          method: 'POST',
          
          headers: {
            'Content-Type': 'application/json',
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify({ 
            Email: email, 
          }),
          credentials: 'include',
        }
      );
      
      if (response.ok) {
        toast.success("The email has been sent!")
        setTimeout(() => navigate('/'),2000)
        
      }else if (response.status === 500){
        toast.error("Email not exist!")
      }
    } catch (error) {
      toast.error("Failed to send email, please try again!")
    }
  }

  return (
    <div className="full_container">
      <Toaster />
      <div className="container">
        <div className="center verticle_center full_height">
          <div className="login_section">
            <div className="logo_login">
              <div className="center">
                {/* <img className="login-logo" src={logo} alt="Logo" /> */}
                <img className="login-carewindow" width="500" src={carewindow_blue} alt="CareWindow" />
              </div>
            </div>

            <div className="login_form">
              <text style={{color:'black'}}>Enter your email address below and we'll send you a link to reset your password.</text>
              <form id="email-form" onSubmit={handleSubmit}>
                <fieldset>
                  <div className="field">
                    <label className="label_field">Email Address</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    
                  </div>

                  <div className="field margin_0">
                    <label className="label_field hidden">hidden label</label>

                    <button style={{width:'120px'}} className="btn cur-p btn-primary" type="submit" >
                      Send Email
                    </button>
                    <Link className="forgot" to="/">Return to Login Page</Link>
                  </div>
                  
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Send_Email