import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../css/jac.css";
//import logo from "../images/logo.png";
import carewindow from "../images/carewindow.png";
//import trashIcon from "../images/alternate-trash.224x256.png";
//import user_img from "../images/layout_img/user_img.jpg";
// import { Link } from "react-router-dom";
// import Backbutton from "../images/arrow_back.png";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
//toast
import toast, { Toaster } from "react-hot-toast";

// trash icon
import TrashIcon from "@mui/icons-material/DeleteRounded";
//pencil icon
import EditIcon from "@mui/icons-material/Edit";
import useAccountStatus from "./useAccountStatus";
import { generateRandomString } from './header';

// CSS
import "../css/bootstrap.min.css";
import "../css/style.css";
import "../css/responsive.css";
import "../css/color_2.css";
import "../css/bootstrap-select.css";
import "../css/perfect-scrollbar.css";
import "../css/custom.css";
import "../css/jquery.fancybox.css";
import "../css/bootstrap.min.css";

function Organise() {
  const [selectedMenu, setSelectedMenu] = useState("organizations");
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const isSuperAdmin = userInfo?.role === "superAdmin";
  const isAdmin = userInfo?.role === "admin";
  const isManager = userInfo?.role === "manager";
  const isStaff = userInfo?.role === "staff";

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("users");
  // const {isAuthenticated, accountDeleted} = useAccountStatus();

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      const parsedUserInfo = JSON.parse(storedUserInfo);
      setUserInfo(parsedUserInfo);
    }
  }, []);

  useEffect(() => {
    if ((isStaff || isManager) && userInfo?.organizationGuid)
      fetchOrganization();
  }, [isStaff, userInfo,isManager]);

  const fetchOrganization = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/organizations/${userInfo.organizationGuid}`,
        {
          method: "GET",
          
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30),
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedOrg(data);
      } else {
        console.log("Failed to fetch organization.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const truncateEmail = (email) => {
    if (email.length <= 20) {
      return email;
    }
    const start = email.substring(0, 20);
    
    return `${start}.....`;
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleBackClick = () => {
    setSelectedOrg(null);
  };

  const handleMenuClick = async (menu) => {
    if (menu === "logout") {
      navigate("/");
    } else if (menu === "organization") {
      await fetchOrganization();
    } else {
      setSelectedMenu(menu);
      setSelectedOrg(null);
    }
  };

  const handleOrgClick = (org) => {
    setSelectedOrg(org);
  };

  const handleAddOrganization = (newOrg) => {
    setOrganizations([...organizations, newOrg]);
  };

  const handleDeleteOrganization = async (orgId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/organizations/${orgId}`,
        {
          method: "DELETE",
          
          credentials: "include",
        }
      );

      if (response.ok) {
        const updatedOrganizations = organizations.filter(
          (org) => org.id !== orgId
        );
        setOrganizations(updatedOrganizations);
        toast.success("Organisation deleted!");
      } else {
        toast.error(
          "Failed to delete organisation. Please refresh the page or check your network connection."
        );
      }
    } catch (error) {
      toast.error(
        "Failed to delete organisation. Please refresh the page or check your network connection."
      );
    }
  };

  const handleUpdateOrganization = (updatedOrg) => {
    const updatedOrgs = organizations.map((org) =>
      org.id === updatedOrg.id ? updatedOrg : org
    );
    setOrganizations(updatedOrgs);
  };

  const handleInviteAdmin = (newAdmin) => {
    setAdmins([...admins, newAdmin]);
  };

  const handleDeleteAdmin = (adminId) => {
    setAdmins(admins.filter((admin) => admin.id !== adminId));
  };

  const handleUpdateAdmin = (updatedAdmin) => {
    const updatedAdmins = admins.map((admin) =>
      admin.id === updatedAdmin.id ? updatedAdmin : admin
    );
    setAdmins(updatedAdmins);
  };

  // const handleTabClick = (tab) => {
  //   setSelectedTab(tab);
  // };

  return (
    <div className="body-page">
      <Toaster />
      <Sidebar
        selectedMenu={selectedMenu}
        handleMenuClick={handleMenuClick}
        currentUserRole={userInfo?.role}
      />
      <div id="content">
        <div className="topbar" style={{zIndex:1048}}>
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="full">
              {/*<button*/}
              {/*  type="button"*/}
              {/*  id="sidebarCollapse"*/}
              {/*  className="sidebar_toggle"*/}
              {/*  style={{ height: "61px", width: "70px" }}*/}
              {/*></button>*/}
              <div
                style={{
                  display: "inline-block",
                  marginLeft: "25px",
                  marginTop: "10px",
                }}
              >
                <h1 style={{ color: "white" }}>CareWindow Admin</h1>
              </div>

              <div className="right_topbar">
                <div
                  className="icon_info"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* {selectedOrg && (
                    <div className="tabs">
                      <button
                        className={`users-button ${selectedTab === 'users' ? 'active' : ''}`}
                        onClick={() => handleTabClick('users')}
                      >
                        Users
                      </button>
                      <button
                        className={`details-button ${selectedTab === 'details' ? 'active' : ''}`}
                        onClick={() => handleTabClick('details')}
                      >
                        Details
                      </button>
                    </div>
                  )} */}
                  <ul className="user_profile_dd">
                    <li className={`dropdown ${isOpen ? "show" : ""}`}>
                      <a
                        className="dropdown-toggle"
                        onClick={toggleDropdown}
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-user black_color"></i>
                        <span className="name_user">
                          {userInfo ? userInfo.name : "Guest" }
                        </span>
                        
                      </a>
                      <div className={`dropdown-menu ${isOpen ? "show" : ""}`}>
                        
                        <span style={{color:'black'}}>{userInfo ? truncateEmail(userInfo.email) : "Email address"}</span>
                        
                          
                        
                        <a
                          className="dropdown-item"
                          onClick={async () => {
                            try {
                              const response = await fetch(
                                `${process.env.REACT_APP_API_URL}/logout`,
                                {
                                  method: "POST",
                                  
                                  headers: {
                                    "Content-Type": "application/json",
                                    'portalscope': 'b3NMWOVzfdRUjrW',
                                    'validatereq': generateRandomString(30)
                                  },
                                  credentials: "include",
                                }
                              );

                              if (response.ok) {
                                localStorage.removeItem("email");
                                localStorage.removeItem("password");
                                localStorage.removeItem("rememberMe");
                                localStorage.removeItem("userInfo");
                                navigate("/");
                              } else {
                                console.log("Logout failed.");
                              }
                            } catch (error) {
                              console.log("Error:", error);
                            }
                          }}
                        >
                          <span>Log Out</span>{" "}
                          <i className="fa fa-sign-out"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="content">
          {selectedOrg && (
            <ManageList
              currentUserRole={userInfo?.role}
              org={selectedOrg}
              onBackClick={handleBackClick}
              onUpdateOrganization={handleUpdateOrganization}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedMenu === "organizations" && !selectedOrg && (
            <OrganizationList
              organizations={organizations}
              onOrgClick={handleOrgClick}
              onAddOrganization={handleAddOrganization}
              onDeleteOrganization={handleDeleteOrganization}
              isAdmin={isAdmin}
              isSuperAdmin={isSuperAdmin}
            />
          )}
          {selectedMenu === "admins" && !selectedOrg && (
            <AdminList
              admins={admins}
              onInviteAdmin={handleInviteAdmin}
              onDeleteAdmin={handleDeleteAdmin}
              onUpdateAdmin={handleUpdateAdmin}
              isSuperAdmin={isSuperAdmin}
              isStaff={isStaff}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function Sidebar({ selectedMenu, handleMenuClick, currentUserRole }) {
  const navigate = useNavigate();
  const isAdmin = currentUserRole === "admin";
  const isSuperAdmin = currentUserRole === "superAdmin";
  const isManager = currentUserRole === "manager";
  const isStaff = currentUserRole === "staff";

  return (
    <div className="dashboard dashboard_2">
      <nav id="sidebar" style={{zIndex:1049}}>
        <div className="sidebar_blog_1">
          <div className="sidebar-header">
            <div
              className="logo_section"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0",
              }}
            >
              <img
                className="img-responsive"
                src={carewindow}
                alt="carewindow"
                style={{ height: "52px" }}
              />
            </div>
          </div>
          {/* <div className="sidebar_user_info">
            <div className="icon_setting"></div>
            <img className="img-responsive" src={logo} alt="logo" style={{ backgroundColor: "transparent" }} /> */}
          {/* <div className="user_profle_side">
            <div className="user_img">
              <img
                className="img-responsive"
                src={user_img}
                alt="#"
              />
            </div>
            <div className="user_info">
              <h6>John David</h6>
              <p>
                <span className="online_animation"></span> Online
              </p>
            </div>
          </div> */}
          {/* </div> */}
        </div>
        <div className="sidebar_blog_2">
          <ul
            className="list-unstyled components"
            style={{ marginTop: "50px" }}
          >
            {isAdmin || isSuperAdmin ? (
              <li className={selectedMenu === "organizations" ? "active" : ""}>
                <a href="#" onClick={() => handleMenuClick("organizations")}>
                  <i className="fa fa-clock-o orange_color"></i>{" "}
                  <span>Organisations</span>
                </a>
              </li>
            ) : null}
            {(isAdmin || isSuperAdmin) && (
              <li className={selectedMenu === "admins" ? "active" : ""}>
                <a href="#" onClick={() => handleMenuClick("admins")}>
                  <i className="fa fa-diamond purple_color"></i>{" "}
                  <span>Admins</span>
                </a>
              </li>
            )}
            {isStaff || isManager ? (
              <li className={selectedMenu === "organization" ? "active" : ""}>
                <a href="#" onClick={() => handleMenuClick("organization")}>
                  <i className="fa fa-clock-o orange_color"></i>{" "}
                  <span>Organisation</span>
                </a>
              </li>
            ) : null}
            <li>
              <a
                href="#"
                onClick={async (e) => {
                  e.preventDefault();
                  try {
                    const response = await fetch(
                      `${process.env.REACT_APP_API_URL}/logout`,
                      {
                        method: "POST",
                        
                        headers: {
                          "Content-Type": "application/json",
                          'portalscope': 'b3NMWOVzfdRUjrW',
                          'validatereq': generateRandomString(30)
                        },
                        credentials: "include",
                      }
                    );

                    if (response.ok) {
                      // Log out successfully to log in page
                      localStorage.removeItem("email");
                      localStorage.removeItem("password");
                      localStorage.removeItem("rememberMe");
                      localStorage.removeItem("userInfo");
                      navigate("/");
                    } else {
                      console.log("Logout failed.");
                    }
                  } catch (error) {
                    console.log("Error:", error);
                  }
                }}
              >
                <i className="fa fa-sign-out red_color"></i>{" "}
                <span>Log out</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

function OrganizationList({
  onOrgClick,
  onAddOrganization,
  onDeleteOrganization,
  isAdmin,
  isSuperAdmin,
  isStaff,
}) {
  const [organizations, setOrganizations] = useState([]);
  const [deleteOrgId, setDeleteOrgId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newOrg, setNewOrg] = useState({
    name: "",
    code: "",
    contact: "",
    phoneNumber: "",
    address: "",
    pin: Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0"),
  });
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isAdmin || isSuperAdmin) {
      fetchOrganizations();
    }
  }, [isAdmin, isSuperAdmin]);

  const fetchOrganizations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/organizations`,
        {
          method: "GET",
          
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setOrganizations(data);
      } else {
        console.log("Failed to fetch organizations.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleAddClick = () => {
    setNewOrg({
      name: "",
      contact: "",
      phoneNumber: "",
      address: "",
      code: "",
      pin: Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0"),
    });
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    setNewOrg({ ...newOrg, [e.target.name]: e.target.value });
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    if (!newOrg.code.trim()) {
      toast.error("Organisation Id is required!");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/organizations`,
        {
          method: "POST",
          
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify(newOrg),
          credentials: "include",
        }
      );

      if (response.ok) {
        const createdOrg = await response.json();
        setShowModal(false);
        onOrgClick(createdOrg);
        fetchOrganizations();
        toast.success("Organisation added!");
      } else if (response.status === 400) {
        const error = await response.json();
        toast.error(error.error);
      }
    } catch (error) {
      toast.error(
        "Failed to add organisation. Please contact the Carewindow team."
      );
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredOrganizations = organizations.filter((org) =>
    Object.values(org).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const truncateAddress = (address) => {
    const maxLength = 30;
    if (address && address.length > maxLength) {
      return address.substring(0, maxLength) + "...";
    }
    return address || "No address provided";
  };

  const handleDeleteClick = (orgId) => {
    setDeleteOrgId(orgId);
  };

  const handleDeleteConfirm = async () => {
    try {
      await onDeleteOrganization(deleteOrgId);
      setDeleteOrgId(null);
      fetchOrganizations();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteOrgId(null);
  };
  const handleEditClick = (orgGuid) => {
    navigate(`/userboard/${orgGuid}`);
  };

  return (
    <div className="midde_cont">
      <div className="container-fluid">
        <div className="row column_title">
          <div className="col-md-12">
            <div className="page_title">
              <h2>Organisation</h2>
            </div>
          </div>
        </div>

        {/* <div className="container"> */}
        <div className="actions col-md-12">
          <button
            className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
            onClick={handleAddClick}
          >
            <b>Add +</b>
          </button>
          <div className="search-wrapper">
            <input
              type="text"
              placeholder="Search"
              className="search-input"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <i className="search-icon"></i>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-wrap">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    {/* <th>Logo</th> */}
                    <th>Name</th>
                    <th>Contact Person</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    {isAdmin || isSuperAdmin ? <th>&nbsp;</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {filteredOrganizations.map((org) => (
                    <tr key={org.id} onClick={() => onOrgClick(org)}>
                      <td>{org.code}</td>
                      {/* <td>Logo</td> */}
                      <td>{org.name}</td>

                      <td>{org.contact}</td>
                      <td>{org.phoneNumber}</td>
                      <td>{truncateAddress(org.address)}</td>
                      {isAdmin || isSuperAdmin ? (
                        <td>
                          <TrashIcon
                            sx={{
                              fontSize: "1.5rem", // 调整图标大小
                              color: "black", // 调整图标颜色
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(org.id);
                            }}
                          />
                          <EditIcon
                            onClick={() => handleEditClick(org.id)}
                            sx={{
                              fontSize: "1.5rem", // 调整图标大小
                              color: "black", // 调整图标颜色
                              marginRight: "10px",
                              cursor: "pointer",
                            }}
                          />
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      {showModal && (
        <div className="modal" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ width: "400px" }}>
              <div className="modal-header">
                <h1 className="modal-title">Add Organisation</h1>
                <button
                  className="close-button"
                  onClick={() => setShowModal(false)}
                >
                  &times;
                </button>
              </div>
              <form className="contact-form">
                
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    value={newOrg.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="organizationId" style={{ marginTop: "10px" }}>
                    Organisation Id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="code"
                    id="organizationId"
                    value={newOrg.code}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="contact">Contact Person</label>
                  <input
                    type="text"
                    className="form-control"
                    name="contact"
                    id="contact"
                    value={newOrg.contact}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={newOrg.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    id="address"
                    value={newOrg.address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="modal-footer">
                  <input
                    type="submit"
                    className="btn cur-p btn-secondary"
                    value="Cancel"
                    onClick={() => setShowModal(false)}
                  />
                  <input
                    type="submit"
                    className="btn cur-p btn-primary"
                    value="Save"
                    onClick={handleSaveClick}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {deleteOrgId && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Delete Organisation</h1>
                <button
                  type="button"
                  className="close-button"
                  onClick={handleDeleteCancel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure to delete{" "}
                  <strong>
                    {organizations.find((org) => org.id === deleteOrgId)?.name}
                  </strong>
                  &nbsp;?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function AdminList({ isSuperAdmin }) {
  const [admins, setAdmins] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [newAdmin, setNewAdmin] = useState({
    name: "",
    email: "",
    isSuperAdmin: false,
  });
  const [emailError, setEmailError] = useState("");
  const [deleteAdminId, setDeleteAdminId] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [editedAdmin, setEditedAdmin] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admins`, {
        method: "GET",
        
        headers: {
          "Content-Type": "application/json",
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': generateRandomString(30)
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        const adminsWithStatus = data.map((admin) => {
          const passwordHash = admin.passwordHash || "";
          const status = passwordHash ? "active" : "pending";
          return { ...admin, status };
        });
        setAdmins(adminsWithStatus);
      } else {
        console.log("Failed to fetch admins.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleInviteClick = () => {
    console.log(isSuperAdmin);
    if (isSuperAdmin) {
      setShowInviteModal(true);
    }
  };

  const handleAdminInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewAdmin({
      ...newAdmin,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    if (isSuperAdmin) {
      
      if (!newAdmin.email.includes("@")) {
        setEmailError("Please enter a valid email address");
        return;
      }
      setEmailError(""); 

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/invite-admin`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            body: JSON.stringify({
              name: newAdmin.name,
              email: newAdmin.email,
              isSuperAdmin: newAdmin.isSuperAdmin,
            }),
            credentials: "include",
          }
        );

        if (response.ok) {
          const createdAdmin = await response.json();
          setAdmins([...admins, createdAdmin]);
          setShowInviteModal(false);
          toast.success("Admin invited!");
          fetchAdmins();
        } else if (response.status === 400) {
          toast.error(
            "The provided email is already taken. Please use another one."
          );
        }
      } catch (error) {
        toast.error(
          "Failed to invite admin. Please check your network connection and try again."
        );
      }
    }
  };

  const handleInviteCancel = () => {
    setShowInviteModal(false);
  };

  const handleDeleteAdminClick = (userId) => {
    if (isSuperAdmin) {
      setDeleteAdminId(userId);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteAdminConfirm = async () => {
    console.log({ deleteAdminId });
    if (isSuperAdmin && deleteAdminId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admins/${deleteAdminId}`,
          {
            method: "DELETE",
            
            credentials: "include",
          }
        );

        if (response.ok) {
          const updatedAdmins = admins.filter(
            (admin) => admin.userId !== deleteAdminId
          );
          setAdmins(updatedAdmins);
          setDeleteAdminId(null);
          setShowDeleteModal(false);
          toast.success("Admin deleted!");
        } else {
          toast.error(
            "Failed to delete admin. Please check your network connection or refresh the page."
          );
        }
      } catch (error) {
        toast.error(
          "Failed to delete admin. Please check your network connection or refresh the page."
        );
      }
    }
  };

  const handleDeleteAdminCancel = () => {
    setDeleteAdminId(null);
    setShowDeleteModal(false);
  };

  const handleAdminClick = (admin) => {
    if (isSuperAdmin) {
      setSelectedAdmin(admin);
      setEditedAdmin(admin);
    }
  };

  const handleBackClick = () => {
    setSelectedAdmin(null);
    setEditedAdmin(null);
  };

  const handleEditAdminInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedAdmin({
      ...editedAdmin,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleEditAdminSubmit = async () => {
    if (isSuperAdmin && editedAdmin) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admins/${editedAdmin.userId}`,
          {
            method: "PUT",
            
            headers: {
              "Content-Type": "application/json",
              'portalscope': 'b3NMWOVzfdRUjrW',
              'validatereq': generateRandomString(30)
            },
            body: JSON.stringify(editedAdmin),
            credentials: "include",
          }
        );

        if (response.ok) {
          const updatedAdmins = admins.map((admin) =>
            admin.id === editedAdmin.userId ? editedAdmin : admin
          );
          setAdmins(updatedAdmins);
          setSelectedAdmin(null);
          setEditedAdmin(null);
          toast.success("Admin updated!");
          fetchAdmins();
        } else {
          toast.error(
            "Failed to update admin. Please check your network connection or refresh the page."
          );
        }
      } catch (error) {
        toast.error(
          "Failed to update admin. Please check your network connection or refresh the page."
        );
      }
    }
  };

  if (selectedAdmin) {
    return (
      <AdminDetails
        admin={selectedAdmin}
        editedAdmin={editedAdmin}
        onBackClick={handleBackClick}
        onEditAdminInputChange={handleEditAdminInputChange}
        onEditAdminSubmit={handleEditAdminSubmit}
        isSuperAdmin={isSuperAdmin}
      />
    );
  }

  return (
    <div className="midde_cont">
      <div className="container-fluid">
        <div className="row column_title">
          <div className="col-md-12">
            <div className="page_title">
              <h2>Admins</h2>
            </div>
          </div>
        </div>
        {/* <div className="container"> */}
        <div className="actions col-md-12">
          <button
            className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
            onClick={handleInviteClick}
            disabled={!isSuperAdmin}
          >
            <b>Invite Admin +</b>
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-wrap">
              <table className="table table-responsive-xl table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th style={{ textAlign: "center", alignContent: "center" }}>
                      Can add/remove admins
                    </th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {admins.map((admin) => (
                    <tr key={admin.id} onClick={() => handleAdminClick(admin)}>
                      <td>{admin.name}</td>
                      <td>{admin.email}</td>
                      <td
                        style={{ textAlign: "center", alignContent: "center" }}
                      >
                        <input
                          type="checkbox"
                          checked={admin.isSuperAdmin}
                          readOnly
                        />
                      </td>

                      <td className="status">
                        {admin.passwordHash ? (
                          <span className="active">{admin.status}</span>
                        ) : (
                          <span className="waiting">{admin.status}</span>
                        )}
                      </td>
                      <td>
                        <TrashIcon
                          sx={{
                            fontSize: "1.5rem", // 调整图标大小
                            color: "black", // 调整图标颜色
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAdminClick(admin.userId);
                          }}
                        />
                        <EditIcon
                          sx={{
                            fontSize: "1.5rem", // 调整图标大小
                            color: "black", // 调整图标颜色
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* </div> */}
        </div>

        {showInviteModal && (
          <div className="modal">
            <div className="modal-content" style={{ width: "400px" }}>
              <div className="modal-header">
                <h1>Invite Admin</h1>
                <button className="close-button" onClick={handleInviteCancel}>
                  &times;
                </button>
              </div>
              <form className="contact-form">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={newAdmin.name}
                    onChange={handleAdminInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={newAdmin.email}
                    onChange={handleAdminInputChange}
                  />
                  {emailError && <div className="error-message" style={{color:'red'}}>{emailError}</div>}
                </div>
                <div className="form-group">
                  <label>
                    <input
                      className="form-checkbox"
                      type="checkbox"
                      name="isSuperAdmin"
                      checked={newAdmin.isSuperAdmin}
                      onChange={handleAdminInputChange}
                    />
                    &nbsp;Can create admins
                  </label>
                </div>
                <div className="modal-footer">
                  <input
                    type="submit"
                    className="btn cur-p btn-secondary"
                    value="Cancel"
                    onClick={handleInviteCancel}
                  />
                  <input
                    type="submit"
                    className="btn cur-p btn-primary"
                    value="Invite"
                    onClick={handleInviteSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        )}
        {showDeleteModal && (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title">Delete Admin</h1>
                  <button
                    type="button"
                    className="close-button"
                    onClick={handleDeleteAdminCancel}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to delete{" "}
                    <strong>
                      {
                        admins.find((admin) => admin.userId === deleteAdminId)
                          ?.name
                      }
                    </strong>
                    ?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleDeleteAdminCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteAdminConfirm}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function AdminDetails({
  admin,
  editedAdmin,
  onBackClick,
  onEditAdminInputChange,
  onEditAdminSubmit,
  isSuperAdmin,
}) {
  return (
    <div className="midde_cont">
      <div className="container-fluid">
        <div className="row column_title">
          <div className="col-md-12">
            <div
              className="page_title"
              style={{ display: "flex", alignItems: "center" }}
            >
              <ArrowBackRoundedIcon
                onClick={onBackClick}
                sx={{
                  fontSize: "1rem", // 调整图标大小
                  color: "black", // 调整图标颜色
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />

              <h2 style={{ flex: "1" }}>{editedAdmin.name}</h2>
            </div>
          </div>
        </div>

        <div className="full inner_elements">
          {/* <div className="container"> */}
          <div className="col-md-12">
            <div className="admin-details">
              <div className="col-md-7 col-lg-8">
                <form
                  className="needs-validation"
                  noValidate
                  style={{ margin: "100px 0px 0px 0px" }}
                >
                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="username" className="form-label">
                        Admin Name
                      </label>
                      <div className="input-group has-validation">
                        <span className="input-group-text">@</span>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={editedAdmin.name}
                          onChange={onEditAdminInputChange}
                          readOnly={!isSuperAdmin}
                        />
                        <div className="invalid-feedback">
                          Your Admin Name is required.
                        </div>
                      </div>
                    </div>
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="you@example.com"
                        name="email"
                        value={editedAdmin.email}
                        onChange={onEditAdminInputChange}
                        readOnly
                      />
                      <div className="invalid-feedback">
                        Please enter a valid email address.
                      </div>
                    </div>
                  </div>
                  {isSuperAdmin && (
                    <div className="form-check" style={{ marginTop: "1em" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="isSuperAdmin"
                        checked={editedAdmin.isSuperAdmin}
                        onChange={onEditAdminInputChange}
                        id="isSuperAdmin"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isSuperAdmin"
                      >
                        Can create admins
                      </label>
                    </div>
                  )}
                  <hr className="my-4" />
                  {isSuperAdmin && (
                    <button
                      className="btn cur-p btn-primary"
                      type="button"
                      onClick={onEditAdminSubmit}
                    >
                      Save
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

function ManageList({
  org,
  onBackClick,
  onUpdateOrganization,
  currentUserRole,
  selectedTab,
  setSelectedTab,
}) {
  const [editedOrg, setEditedOrg] = useState({
    ...org,
    organizationId: org.organizationId,
    name: org.name,
    address: org.address,
    phoneNumber: org.phoneNumber,
  });
  const [users, setUsers] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [newUser, setNewUser] = useState({ name: "", email: "", role: "" });
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isManager = currentUserRole === "manager";
  const isStaff = currentUserRole === "staff";

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${org.id}`,
        {
          method: "GET",
          
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const usersWithStatus = data.map((user) => {
          const passwordHash = user.passwordHash || "";
          const status = passwordHash ? "active" : "pending";
          return { ...user, status };
        });
        setUsers(usersWithStatus);
      } else {
        console.log("Failed to fetch users.");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab === "users") {
      fetchUsers();
    }
  };

  const handleInputChange = (e) => {
    setEditedOrg({ ...editedOrg, [e.target.name]: e.target.value });
  };

  const handleSaveClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/organizations/${editedOrg.id}`,
        {
          method: "PUT",
          
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify(editedOrg),
          credentials: "include",
        }
      );

      if (response.ok) {
        onUpdateOrganization(editedOrg);
        toast.success("Organisation updated!");
      } else {
        toast.error(
          "Failed to update organisation. Please check your network connection or refresh the page."
        );
      }
    } catch (error) {
      toast.error(
        "Failed to update organisation. Please check your network connection or refresh the page."
      );
    }
  };

  const handleInviteClick = () => {
    setShowInviteModal(true);
  };

  const handleUserInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/invite-user`,
        {
          method: "POST",
          
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify({
            organizationGuid: org.id,
            name: newUser.name,
            email: newUser.email,
            role: newUser.role,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newUserWithStatus = {
          ...newUser,
          id: Date.now(),
          status: "pending",
        };
        setUsers([...users, newUserWithStatus]);
        setNewUser({ name: "", email: "", role: "" });
        setShowInviteModal(false);
        toast.success("User invited!");
        fetchUsers();
      } else if (response.status === 400) {
        toast.error(
          "The provided email is already taken. Please use another one."
        );
      }
    } catch (error) {
      toast.error(
        "Failed to invite user. Please check your network connection and try again."
      );
    }
  };

  const handleInviteCancel = () => {
    setShowInviteModal(false);
  };

  const handleDeleteUserClick = (userId) => {
    console.log(userId);
    if (userId) {
      setDeleteUserId(userId);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteUserConfirm = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${deleteUserId}`,
        {
          method: "DELETE",
          
          credentials: "include",
        }
      );

      if (response.ok) {
        const updatedUsers = users.filter((user) => user.id !== deleteUserId);
        setUsers(updatedUsers);
        setDeleteUserId(null);

        toast.success("User deleted!");
        setShowDeleteModal(false);
        fetchUsers();
      } else {
        toast.error(
          "Failed to delete user. Please check your network connection or refresh the page."
        );
      }
    } catch (error) {
      toast.error(
        "Failed to delete user. Please check your network connection or refresh the page."
      );
    }
  };

  const handleDeleteUserCancel = () => {
    setShowDeleteModal(false);
  };

  const handleUserClick = (user) => {
    if (isStaff) return;
    setEditedUser(user);
    setShowEditUserModal(true);
  };

  const handleEditUserInputChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleEditUserSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${editedUser.userId}`,
        {
          method: "PUT",
          
          headers: {
            "Content-Type": "application/json",
            'portalscope': 'b3NMWOVzfdRUjrW',
            'validatereq': generateRandomString(30)
          },
          body: JSON.stringify({
            name: editedUser.name,
            organizationGuid: org.id,
            role: editedUser.role,
          }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const updatedUsers = users.map((user) =>
          user.id === editedUser.id ? editedUser : user
        );
        setUsers(updatedUsers);
        setShowEditUserModal(false);
        toast.success("User updated!");
        fetchUsers();
      } else {
        toast.error(
          "Failed to update user. Please check your network connection or refresh the page."
        );
      }
    } catch (error) {
      toast.error(
        "Failed to update user. Please check your network connection or refresh the page."
      );
    }
  };

  const handleEditUserCancel = () => {
    setShowEditUserModal(false);
  };

  return (
    <div className="midde_cont">
      <div className="container-fluid">
        <div className="row column_title">
          <div className="col-md-12">
            <div
              className="page_title"
              style={{ display: "flex", alignItems: "center" }}
            >
              {isManager || isStaff ? null : (
                <ArrowBackRoundedIcon
                  onClick={onBackClick}
                  sx={{
                    fontSize: "1rem", // 调整图标大小
                    color: "black", // 调整图标颜色
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />
              )}
              <h2 style={{ flex: "1" }}>{org.name}</h2>
            </div>
          </div>
        </div>
        <div className="full inner_elements">
          {/* <div className="container"> */}
          <div className="col-md-12">
            <div className="tab_style2">
              <div className="tabbar padding_infor_info">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                    <div
                      className={`nav-item nav-link ${
                        selectedTab === "users" ? "active" : ""
                      }`}
                      id="nav-users-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="nav-users"
                      aria-selected={selectedTab === "users"}
                      onClick={() => handleTabClick("users")}
                    >
                      Users
                    </div>
                    <div
                      className={`nav-item nav-link ${
                        selectedTab === "details" ? "active" : ""
                      }`}
                      id="nav-details-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="nav-details"
                      aria-selected={selectedTab === "details"}
                      onClick={() => handleTabClick("details")}
                    >
                      Details
                    </div>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className={`tab-pane fade ${
                      selectedTab === "users" ? "show active" : ""
                    }`}
                    id="nav-users"
                    role="tabpanel"
                    aria-labelledby="nav-users-tab"
                  >
                    <div className="actions">
                      {isStaff ? null : (
                        <button
                          className="btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold"
                          onClick={handleInviteClick}
                        >
                          <b>Invite +</b>
                        </button>
                      )}
                    </div>
                    <table className="table table-hover">
                      <thead>
                        <tr key="headers">
                          <th key="Name">Name</th>
                          <th key="Email">Email</th>
                          <th key="Role">Role</th>
                          <th key="Status">Status</th>
                          {isStaff ? null : <th></th>}
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user) => (
                          <tr
                            key={user.id}
                            onClick={() => handleUserClick(user)}
                          >
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td className="status">
                              {user.passwordHash ? (
                                <span className="active">{user.status}</span>
                              ) : (
                                <span className="waiting">{user.status}</span>
                              )}
                            </td>
                            {isStaff ? null : (
                              <td>
                                <TrashIcon
                                  sx={{
                                    fontSize: "1.5rem", // 调整图标大小
                                    color: "black", // 调整图标颜色
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    console.log(user);
                                    e.stopPropagation();
                                    handleDeleteUserClick(user.userId);
                                  }}
                                />
                                <EditIcon
                                  sx={{
                                    fontSize: "1.5rem", // 调整图标大小
                                    color: "black", // 调整图标颜色
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      selectedTab === "details" ? "show active" : ""
                    }`}
                    id="nav-details"
                    role="tabpanel"
                    aria-labelledby="nav-details-tab"
                  >
                    <div className="admin-details">
                      <div className="col-md-7 col-lg-8">
                        <form
                          className="needs-validation"
                          noValidate
                          style={{ margin: "0px 0px 0px 0px" }}
                        >
                          <div className="row g-3">
                            <div className="col-12">
                              <label htmlFor="name" className="form-label">
                                Name
                              </label>
                              <div className="input-group has-validation">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  value={editedOrg.name}
                                  onChange={handleInputChange}
                                  readOnly={isStaff}
                                  required
                                />
                                <div className="invalid-feedback">
                                  Organisation Name is required.
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-12"
                              style={{ marginTop: "1em" }}
                            >
                              <label
                                htmlFor="organizationId"
                                className="form-label"
                              >
                                Organisation ID
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="organizationId"
                                name="organizationId"
                                value={editedOrg.code}
                                onChange={handleInputChange}
                                readOnly
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter a valid Organisation ID.
                              </div>
                            </div>
                            <div
                              className="col-12"
                              style={{ marginTop: "1em" }}
                            >
                              <label htmlFor="contact" className="form-label">
                                Contact Person
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contact"
                                name="contact"
                                value={editedOrg.contact}
                                onChange={handleInputChange}
                                readOnly={isStaff}
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter a valid Contact Person.
                              </div>
                            </div>
                            <div
                              className="col-12"
                              style={{ marginTop: "1em" }}
                            >
                              <label
                                htmlFor="phoneNumber"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={editedOrg.phoneNumber}
                                onChange={handleInputChange}
                                readOnly={isStaff}
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter a valid Phone Number.
                              </div>
                            </div>
                            <div
                              className="col-12"
                              style={{ marginTop: "1em" }}
                            >
                              <label htmlFor="address" className="form-label">
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                value={editedOrg.address}
                                onChange={handleInputChange}
                                readOnly={isStaff}
                                required
                              />
                              <div className="invalid-feedback">
                                Please enter a valid Address.
                              </div>
                            </div>
                          </div>
                          <hr className="my-4" />
                          {isStaff ? null : (
                            <button
                              className="btn cur-p btn-primary"
                              type="button"
                              onClick={handleSaveClick}
                            >
                              Save
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

        {showInviteModal && (
          <div className="modal" tabIndex={"-1"} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "400px" }}>
                <div className="modal-header">
                  <h1 className="modal-title">Invite User</h1>
                  <button className="close-button" onClick={handleInviteCancel}>
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <form className="contact-form">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        value={newUser.name}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        name="email"
                        value={newUser.email}
                        onChange={handleUserInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">Role</label>
                      <select
                        className="form-control"
                        id="role"
                        name="role"
                        value={newUser.role}
                        onChange={handleUserInputChange}
                        required
                      >
                        <option value="">Select a role</option>
                        <option value="Manager">Manager</option>
                        <option value="Staff">Staff</option>
                      </select>
                    </div>
                    <div className="modal-footer">
                      <input
                        type="submit"
                        className="btn cur-p btn-secondary"
                        value="Cancel"
                        onClick={handleInviteCancel}
                      />
                      <input
                        type="submit"
                        className="btn cur-p btn-primary"
                        value="Invite"
                        onClick={handleInviteSubmit}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title">Delete User</h1>
                  <button
                    type="button"
                    className="close-button"
                    onClick={handleDeleteUserCancel}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Are you sure you want to delete{" "}
                    <strong>
                      {users.find((user) => user.userId === deleteUserId)?.name}
                    </strong>
                    ?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleDeleteUserCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteUserConfirm}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {showEditUserModal && (
          <div className="modal" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "400px" }}>
                <div className="modal-header">
                  <h1 className=" modal-title">Edit User</h1>
                  <button
                    className="close-button"
                    onClick={handleEditUserCancel}
                  >
                    &times;
                  </button>
                </div>
                <form className="contact-form">
                  <div className="form-group" style={{ position: "relative" }}>
                    <label for="staticEmail" className="col-form-label">
                      Email:
                    </label>
                    <div>
                      <input
                        readOnly
                        className="form-control-plaintext"
                        id="staticEmail"
                        type="text"
                        name="email"
                        value={editedUser.email}
                        style={{
                          paddingLeft: "10px",
                          backgroundColor: "#e9ecef",
                        }}
                      />
                    </div>
                    <span>
                      <FontAwesomeIcon
                        icon={faBan}
                        style={{
                          position: "absolute",
                          top: "75%",
                          left: "93%",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                        }}
                      />
                    </span>
                  </div>

                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={editedUser.name}
                      onChange={handleEditUserInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Role</label>
                    <select
                      className="custom-select"
                      name="role"
                      value={editedUser.role}
                      onChange={handleEditUserInputChange}
                    >
                      <option value="Manager">Manager</option>
                      <option value="Staff">Staff</option>
                    </select>
                  </div>

                  <div className="modal-footer">
                    <input
                      type="submit"
                      className="btn cur-p btn-secondary"
                      value="Cancel"
                      onClick={handleEditUserCancel}
                    />
                    <input
                      type="submit"
                      className="btn cur-p btn-primary"
                      value="Save"
                      onClick={handleEditUserSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Organise;
